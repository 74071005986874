import React from 'react'

export default function logoAngular() {
  return (
    <svg id="logo-angular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 369.408 100">
      <g transform="translate(108.658 22.727)">
        <path className="logo-angular-NGULAR" d="M150.6,82.943V37.488h5.844l27.778,36.58V37.488h5.556V82.943h-5.844L156.156,46V82.943Zm84.416-1.8a33.734,33.734,0,0,1-11.833,2.092q-24.675,0-24.675-23.665,0-22.4,23.81-22.439A42.2,42.2,0,0,1,235.016,39v5.051a33.023,33.023,0,0,0-12.049-2.237c-12.41,0-18.615,5.916-18.615,17.6,0,12.626,6.133,18.9,18.326,18.9a31.77,31.77,0,0,0,6.493-.794V62.308h5.844V81.139Zm9.38-15.729V37.488h5.844V65.411c0,8.73,4.329,13.131,13.059,13.131,8.658,0,13.059-4.4,13.059-13.131V37.488H282.2V65.411c0,11.9-6.277,17.893-18.9,17.893S244.4,77.315,244.4,65.411Zm54.113-27.922V78.181h23.81v4.762H292.663V37.488h5.844Zm32.251,45.455h-6.133L347.209,31.5l22.583,51.443H363.3l-5.844-14.286H342.447l1.587-4.762h11.472l-8.514-20.563-16.234,39.61Zm45.31,0V37.488h19.408c8.658,0,12.987,3.608,12.987,10.895,0,5.916-4.257,10.317-12.7,13.131L411.35,82.943h-7.72L389.2,62.525V58.7c8.658-1.371,13.059-4.69,13.059-10.029,0-4.185-2.381-6.277-7.215-6.277H382.057V82.943h-5.988Z" transform="translate(-150.6 -31.5)" fill-rule="evenodd"/>
      </g>
      <path className="logo-angular-shield-left" d="M0,16.595,46.537,0,94.3,16.306,86.58,77.85,46.537,100,7.143,78.139Z" fill-rule="evenodd"/>
      <path className="logo-angular-shield-right"  d="M112.263,16.306,64.5,0V100l40.043-22.15,7.72-61.544Z" transform="translate(-17.963)" fill-rule="evenodd"/>
      <path className="logo-angular-A" d="M53.4,16.2l-29,64.5,10.823-.216,5.844-14.574h25.9L73.318,80.63l10.317.216L53.4,16.2Zm.072,20.707L63.289,57.4h-18.4Z" transform="translate(-6.795 -4.512)" fill-rule="evenodd"/>
    </svg>
  )    
}

