import React, { Component } from 'react'

class LandingPage extends Component {
  render() {
    return (
      <div className="page-landing"></div>
    )
  }
}

export default LandingPage