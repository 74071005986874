import React from 'react'

export default function logoHeroku() {
  return (
    <svg stroke="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 338.333 100">
      <g>
        <path className="logo-heroku-path" d="M72.048,101.951H10.46A9.547,9.547,0,0,1,.921,92.412V11.49A9.547,9.547,0,0,1,10.46,1.951H72.048a9.547,9.547,0,0,1,9.539,9.539v80.9a9.547,9.547,0,0,1-9.539,9.539Z" transform="translate(-0.921 -1.951)"/>
        <path d="M33.737,75.267V35.555s2.562-9.5-31.809,3.883c-.059.2-.059-36.717-.059-36.717L13.1,2.642v23.65s31.474-12.416,31.474,9.4V75.267H33.737Zm6.405-58.337H28.219C32.515,11.688,36.4,2.7,36.4,2.7H48.716s-2.109,5.853-8.573,14.229ZM2.046,75.188V52.583L13.359,63.9,2.046,75.208Z" transform="translate(16.814 10.976)" fill="#fff"/>
        <path className="logo-heroku-path" d="M32.208,63.5V30.984c0-2.286-1.675-3.291-3.666-3.291A34.884,34.884,0,0,0,15.1,30.668V63.5H5.937V3.116H15.1V23.021a44.193,44.193,0,0,1,14.742-2.956c8.317,0,11.431,5.124,11.431,12.14V63.542ZM58.42,43.794c.3,10.918,2.444,13.067,9.164,13.067,5.262,0,11.234-1.774,15.806-3.508l1.518,6.9a42.744,42.744,0,0,1-17.737,4.336c-15.274,0-17.639-8.4-17.639-21.758,0-7.331.532-13.993,2.286-20.1,4.572-1.774,10.7-2.68,18.408-2.68,12.534,0,16.358,6.957,16.358,19.117v4.651H58.42ZM70.048,27.673a59.037,59.037,0,0,0-10.682.788,46.094,46.094,0,0,0-.985,9.007H77.5c0-6.346-1.143-9.775-7.41-9.775Zm33.5,2.956V63.5H94.388V26.569A53.015,53.015,0,0,1,115.614,20.7v7.627c-3.942.3-8.475.788-12.061,2.365Zm36.263,33.9c-13.678,0-19.866-6.7-19.866-22.073,0-16.259,8.179-22.467,19.866-22.467,13.678,0,19.846,6.7,19.846,22.073C159.662,58.319,151.483,64.527,139.816,64.527Zm0-36.855c-7.036,0-10.918,4.04-10.918,14.821,0,11.766,3.291,14.387,10.918,14.387,7.016,0,10.918-3.981,10.918-14.742,0-11.766-3.291-14.426-10.918-14.426Zm64.052,35.81H193.107c-2.523-6.7-6.031-13.8-11.431-19.551h-4.809V63.483H167.7V3.116h9.164V37.8h4.277A55.578,55.578,0,0,0,191.9,21.149h10.307a71.8,71.8,0,0,1-13.067,18.486,129.483,129.483,0,0,1,14.742,23.906Zm17.186,1.064c-8.317,0-11.431-5.124-11.431-12.14V21.129h9.066v32.46c0,2.286,1.675,3.291,3.666,3.291A37.052,37.052,0,0,0,236.248,53.9V21.129h9.164V57.945c-10.84,5.952-19.176,6.642-24.359,6.642Z" transform="translate(92.92 19.844)"/>
      </g>
    </svg>
  )
}