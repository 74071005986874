import React, { Component } from 'react'

class PageSpacer extends Component {
  render() {
    return (
      <div className="page-spacer"></div>
    )
  }
}

export default PageSpacer