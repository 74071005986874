import React from 'react'

export default function logoDjango() {
  return (
    <svg stroke="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.225 100">
      <g>
        <path d="M33.746,0H49.4V72.454a103.258,103.258,0,0,1-20.329,2.134C9.961,74.588,0,65.95,0,49.381,0,33.423,10.572,23.056,26.936,23.056a25.021,25.021,0,0,1,6.81.812V0Zm0,36.471a15.549,15.549,0,0,0-5.285-.812c-7.928,0-12.5,4.879-12.5,13.417,0,8.336,4.371,12.909,12.4,12.909a36.535,36.535,0,0,0,5.387-.406Z"/>
        <path d="M97.035,24.217V60.5c0,12.5-.915,18.5-3.659,23.683-2.542,4.982-5.9,8.132-12.808,11.588L66.033,88.862c6.912-3.252,10.266-6.1,12.4-10.47,2.237-4.472,2.949-9.656,2.949-23.276v-30.9ZM81.382.127H97.035v16.06H81.382Z" transform="translate(-22.734 -0.044)"/>
        <path d="M126.023,39.836a47.1,47.1,0,0,1,20.736-4.675c8.03,0,13.315,2.134,15.653,6.3,1.321,2.338,1.727,5.387,1.727,11.893V85.169A172.252,172.252,0,0,1,141.778,86.9c-13.112,0-19.008-4.576-19.008-14.739,0-10.978,7.827-16.06,27.038-17.687V51.016c0-2.846-1.423-3.862-5.387-3.862a40.992,40.992,0,0,0-18.4,4.778v-12.1Zm24.5,24.9c-10.368,1.016-13.722,2.643-13.722,6.709,0,3.049,1.932,4.472,6.2,4.472a43.183,43.183,0,0,0,7.522-.711Z" transform="translate(-42.267 -12.105)"/>
        <path d="M197.487,38.718a93.874,93.874,0,0,1,24.6-3.557c8.03,0,13.824,1.829,17.281,5.387,3.252,3.353,4.268,7.012,4.268,14.84v30.7H227.98V56c0-6-2.033-8.234-7.623-8.234a23.581,23.581,0,0,0-7.218,1.118v37.2H197.487Z" transform="translate(-67.991 -12.105)"/>
        <path d="M276.5,94.675a35.634,35.634,0,0,0,16.771,4.166c10.265,0,14.636-4.166,14.636-14.128v-.3a21.416,21.416,0,0,1-10.164,2.134c-13.722,0-22.465-9.046-22.465-23.378,0-17.789,12.91-27.85,35.779-27.85A98.813,98.813,0,0,1,331.49,37.55l-5.36,11.291c-4.168-.813-.334-.109-3.483-.414v1.627l.2,6.606.1,8.539c.1,2.134.1,4.269.2,6.4v4.269c0,13.417-1.118,19.719-4.472,24.9-4.879,7.624-13.316,11.385-25.31,11.385a44.768,44.768,0,0,1-16.873-3.05V94.675Zm31.1-46.757h-1.626a17.1,17.1,0,0,0-9.046,2.235c-3.76,2.135-5.692,6-5.692,11.486,0,7.827,3.863,12.3,10.775,12.3a19.426,19.426,0,0,0,5.895-1.016V67.536c0-1.829-.1-3.864-.1-6l-.1-7.216-.1-5.184v-1.22Z" transform="translate(-94.773 -12.158)"/>
        <path d="M384.453,34.85c15.653,0,25.208,9.86,25.208,25.818,0,16.365-9.96,26.631-25.818,26.631-15.654,0-25.31-9.86-25.31-25.716C358.534,45.115,368.5,34.85,384.453,34.85Zm-.306,39.844c6,0,9.556-4.981,9.556-13.621,0-8.537-3.456-13.62-9.452-13.62-6.2,0-9.76,4.982-9.76,13.62S378.049,74.694,384.147,74.694Z" transform="translate(-123.436 -11.998)"/>
      </g>
    </svg>
  )
}